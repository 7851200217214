@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #E1E8E7 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}

.remove-spinner::-webkit-outer-spin-button,
.remove-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

fieldset {
  border: 2px solid #008065;
}

legend {
  font-size: 16px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/assets/pxiEyp8kv8JHgFVrJJbecmNE.woff2);
  /* src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2'); */
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/assets/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
  /* https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2 */
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/assets/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* required star  */

.required span:after {
  content: "*";
  color: #db3131;
  padding: 3px;
  font-size: medium;
}

.slick-next:before {
  border-radius: 80% 80% 100% 100% !important;
  border-color: #008065 !important;
  font-size: 22px !important;
  background-color: #008065 !important;
}

.slick-prev:before {
  border-radius: 80% 80% 100% 100% !important;
  border-color: #008065 !important;
  font-size: 22px !important;
  background-color: #008065 !important;
}

/* popup  */
.modal-container {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

/* sidebar open & close in  small view  */
@media only screen and (max-width: 426px) {
  .test {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 425px) {
  .input {
    margin-left: 0px;
    padding-right: 3px;
  }
}

/* homepage preview  */

@media screen and (min-width: 378px) and (max-width: 426px) {
  .homePreview .datePreview {
    margin-right: 64px;
  }
}

/* invoice preview  */
@media screen and (min-width: 391px) and (max-width: 426px) {
  .invoicepreview {
    /* margin-right: 56px; */
  }

}

/* sidebar top for medium screens */
@media only screen and (max-width: 768px) {
  .sidebarTop {
    top: 0px;
  }
}

/* hiding empty div in small screens */
@media only screen and (max-width: 460px) {
  .emptydiv {
    display: none;
  }
}

/* date picker alignment */
.invoice-datepicker {
  min-width: 134px !important;
}


.return-datepicker {
  width: 100% !important;
}

.due-datepicker {
  min-width: 146px !important;
}

@media screen and (min-width: 320px) and (max-width: 376px) {
  .dueUniqDate {
    margin-left: 1.25rem !important;
    width: 130px !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 426px) {
  .dueUniqDate {
    margin-left: 0.75rem !important;
  }

  .invoiceUniqNo {
    /* padding-right: 40px !important; */

  }


}

/* Reverse the date on top */
@media only screen and (max-width: 460px) {
  .dateReverse {
    flex-direction: column-reverse;
  }

  .homePreview {
    margin-bottom: 10px;
  }
}


/* preview column date  */
@media only screen and (max-width: 460px) {
  .pre-reverse {
    flex-direction: column-reverse;
  }
}

/* Header buttons hover effects */

a.HeaderNavBtn:hover {
  color: #008065 !important;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .UniqHeaderLogoImg {
    max-width: 134%;
  }
}

.image-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-container {
  position: relative;
  cursor: pointer;
}

.image {
  display: block;
  width: 100px;
  /* Adjust size as needed */
  height: 100px;
  /* Adjust size as needed */
}

.tick {
  position: absolute;
  top: 55px;
  left: 25px;
  font-size: 24px;
  /* Adjust size as needed */
  font-weight: bolder;
  color: green;
  /* Adjust color as needed */
  border-radius: 100%;
  padding: 2px;
}

.zoomed-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.zoomed-image {
  max-width: 90vw;
  max-height: 90vh;
}

/* .css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px !important;
  } */


.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #008065;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #006655;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #008065;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Custom class for success popup */
.swal-popup-success {
  border: 3px solid #28a745;
  /* Green border */
  font-size: 20px;
  /* Increase font size */
  font-family: poppins;
}

/* Custom class for error popup */
.swal-popup-error {
  border: 3px solid #dc3545;
  /* Red border */
  font-size: 20px;
  /* Increase font size */
  font-family: poppins;
}

span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
  font-family: poppins;
  font-weight: 700;
  color: #008065;
  font-size: medium;
}

/*  */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* register failed popup */
.custom-close-button {
color: white;
background-color: red;
}
/* register success popup */
.custom-ok-button {
  color: white;
  background-color: #008065;
  }
/* swall fire  */
.swal-title {
  background:rgb(212, 52, 52);
  color: #fff;
  padding: 10px 0;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.swal-title1 {
  background:#008065;
  color: #fff;
  padding: 10px 0;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  width: 100%;
  border-radius: 5px 5px 0 0;
}

div:where(.swal2-container) .swal2-html-container {
  padding: 0 !important;
}
.swal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  border: 3px solid #f27474;
  margin: 15px auto;
  position: relative;
}
.swal-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 5px;
  background-color: #f27474;
  border-radius: 2px;
}
.swal-left {
  transform: translate(-50%, -50%) rotate(45deg);
}
.swal-right {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.swal-msg {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.suc-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px; 
  height: 80px;
  border-radius: 50%;
  background-color: white;
  border: 4px solid #8fdc8f; 
  margin: 15px auto;
  position: relative;
}
.suc-mark {
  position: relative;
  width: 40px; 
  height: 25px; 
}
.suc-line {
  position: absolute;
  background-color: #8fdc8f; 
  border-radius: 3px;
}
.suc-left {
  width: 22px; 
  height: 6px;
  transform: rotate(45deg);
  left: -3px;
  bottom: 5px;
}
.suc-right {
  width: 36px;
  height: 6px;
  transform: rotate(-45deg);
  right: -3px;
  top: 9px;
}

/* Loading animation */
.loader {
  width: 35px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #07ad7b;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}